import React from 'react';
function Index() {
    return (

                <div className="container text-center mt-5">
                    <h3 className='mb-2'>Ooops!</h3>
                    <h1 className='mb-5'>404</h1>
                    <h5>The page you were looking for doesn't exist</h5>
                </div>

    )
}

export default Index;